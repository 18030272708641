<template>
  <div>
    <el-dialog
      title="添加方案"
      :visible.sync="dialogVisible"
      :before-close="closeDialoge"
      width="30%"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="方案名" prop="name">
          <el-input v-model="ruleForm.name" placeholder="方案名称"></el-input>
        </el-form-item>
        <el-form-item label="包含词" prop="include">
          <TagArry ref="TagArryRef" @receiveTags="receiveIncludeTags" />
        </el-form-item>
        <el-form-item label="开始时间" prop="start_time">
          <el-date-picker
            v-model="ruleForm.start_time"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="end_time">
          <el-date-picker
            v-model="ruleForm.end_time"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          ></el-date-picker>
        </el-form-item>
        <!-- 
        <el-form-item v-show="formItemVisible" label="排除词" prop="exclude">
          <TagArry @receiveTags="receiveExcludeTags" />
        </el-form-item>
        <el-form-item v-show="formItemVisible" label="群组id" prop="group_ids">
          <TagArry @receiveTags="receiveGroupIdsTags" />
        </el-form-item>
        <el-form-item v-show="formItemVisible" label="用户id" prop="author_ids">
          <TagArry @receiveTags="receiveAuthorIdsTags" />
        </el-form-item>
        <div @click="showMore" class="gjsz_word">
          {{ formItemVisible ? '收起' : '高级设置' }}
        </div> -->
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">
            确 定
          </el-button>
          <el-button @click="closeDialoge">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { addPlan, updateApi } from '../../../api/clue'
import TagArry from './TagArry.vue'
export default {
  name: 'PlanDialog',
  props: ['showDialog'],
  components: { TagArry },
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        name: '',
        include: null,
        exclude: null,
        group_ids: null,
        author_ids: null,
        start_time: null,
        end_time: null
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入方案名',
            trigger: 'blur'
          }
        ],
        include: [
          {
            required: true,
            message: '请输入包含词'
          }
        ]
      },

      formItemVisible: false
    }
  },
  created() {
    this.dialogVisible = this.showDialog
  },
  methods: {
    initFun(row) {
      this.ruleForm = Object.assign({}, row)
      this.$nextTick(() => {
        let arr = row.include.split(',')
        this.$refs.TagArryRef.dynamicTags = arr
      })
    },
    // 展示更多
    showMore() {
      this.formItemVisible = !this.formItemVisible
    },
    // 关闭弹框
    closeDialoge() {
      this.$emit('receiveVal', false)
    },
    receiveIncludeTags(val) {
      this.ruleForm.include = val
    },
    receiveExcludeTags(val) {
      this.ruleForm.exclude = val
    },
    receiveGroupIdsTags(val) {
      this.ruleForm.group_ids = val
    },
    receiveAuthorIdsTags(val) {
      this.ruleForm.author_ids = val
    },

    // 点击确定按钮触发
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          if (this.ruleForm.id) {
            await updateApi(this.ruleForm.id, this.ruleForm)
            this.$message.success('修改成功！')
            this.closeDialoge()
          } else {
            await addPlan(this.ruleForm)
            this.$message.success('添加成功！')
            this.closeDialoge()
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.gjsz_word {
  font-size: 12px;
  padding-left: 8%;
  margin: -10px 0 10px 0;
  font-weight: 600;
  cursor: pointer;
  color: blue;
}
</style>
