<template>
  <div class="app-container">
    <div class="top_box">
      <el-form
        :inline="true"
        :model="queryForm"
        class="demo-form-inline account-top"
      >
        <el-form-item label="方案名">
          <el-input
            v-model="queryForm.name"
            placeholder="方案名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="所属用户" v-if="userInfo.is_staff">
          <el-input
            v-model="queryForm.owner"
            placeholder="所属用户ID"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="搜索">
          <el-input
            v-model="queryForm.search"
            placeholder="模糊检索..."
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="fetchData">刷新</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom_box">
      <el-button @click="showAddDialog" size="mini" type="success">
        添加方案
      </el-button>

      <div class="content_t">
        <el-table
          v-loading="listLoading"
          :data="list"
          element-loading-text="Loading"
          border
          fit
          highlight-current-row
          class="tableList2"
          :header-cell-style="{ background: '#F3F7F9', color: '#909399' }"
          height="100%"
          style="width: 100%"
          algin="center"
          :default-sort="{ prop: 'create_time', order: 'descending' }"
          @sort-change="handleSort"
        >
          <el-table-column prop="id" label="ID" width="100"></el-table-column>
          <el-table-column prop="name" label="方案名"></el-table-column>
          <el-table-column prop="include" label="包含词"></el-table-column>
          <!-- <el-table-column prop="exclude" label="排除词"></el-table-column>
          <el-table-column prop="group_ids" label="群组范围"></el-table-column>
          <el-table-column
            prop="sender_phones"
            label="帐号范围"
          ></el-table-column> -->
          <el-table-column prop="start_time" label="开始时间"></el-table-column>
          <el-table-column prop="end_time" label="结束时间"></el-table-column>
          <el-table-column
            prop="not_read_count"
            label="未读数据"
          ></el-table-column>
          <el-table-column prop="owner" label="查询者" v-if="userInfo.is_staff">
            <template slot-scope="scope">
              {{
                scope.row.owner
                  ? scope.row.owner.oauth_account
                    ? scope.row.owner.oauth_account
                    : scope.row.owner.username
                  : null
              }}
            </template>
          </el-table-column>
          <el-table-column label="查询者备注" v-if="userInfo.is_staff">
            <template slot-scope="scope">
              {{ scope.row.owner ? scope.row.owner.note : null }}
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            prop="create_time"
            sortable="custom"
          ></el-table-column>
          <el-table-column label="操作" width="90">
            <template v-slot="scoped">
              <el-button
                @click="handleEdit(scoped.row)"
                size="mini"
                type="text"
              >
                编辑
              </el-button>
              <el-button
                @click="handleDelete(scoped.row.id)"
                size="mini"
                type="text"
                style="color: #f78989;"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryForm.page_index"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryForm.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count"
      ></el-pagination>
    </div>
    <PlanDialog
      ref="PlanDialogRef"
      v-if="showDialog"
      :showDialog="showDialog"
      @receiveVal="receiveVal"
    />
  </div>
</template>

<script>
import { getPlanList, deletePlan } from '@/api/clue'
import { getManagerStatus } from '@/utils/manager'
import PlanDialog from './components/PlanDialog.vue'

export default {
  name:'cluePlan',
  components: { PlanDialog },
  data() {
    return {
      userInfo: this.$store.state.user.userInfo,
      list: null,
      listLoading: true,
      count: 0,
      isManager: getManagerStatus(),
      queryForm: {
        page_index: 1,
        page_size: 10,
        search: '',
        ordering: '-create_time'
      },
      showDialog: false
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.listLoading = true
      const { count, items } = await getPlanList(this.queryForm)
      this.count = count
      this.list = items
      this.listLoading = false
    },
    async handleSearch() {
      this.queryForm.page_index = 1
      await this.fetchData()
    },
    handleSizeChange(pageSize) {
      this.queryForm.page_size = pageSize
      // 分页变更时处理
      this.fetchData()
    },
    handleCurrentChange(pageIndex) {
      this.queryForm.page_index = pageIndex
      // 分页变更时处理
      this.fetchData()
    },
    showAddDialog() {
      this.showDialog = true
    },
    receiveVal(val) {
      this.showDialog = val
      this.fetchData()
    },
    // 删除当前方案
    handleDelete(id) {
      this.$confirm('此操作将永久删除该方案, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await deletePlan(id)

          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // })
          this.fetchData()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    handleSort({ column, prop, order }) {
      if (order === null) {
        this.queryForm.ordering = null
      } else if (order === 'descending') {
        this.queryForm.ordering = '-' + prop
      } else {
        this.queryForm.ordering = prop
      }

      this.fetchData()
    },
    handleEdit(row) {
      this.showDialog = true
      this.$nextTick(() => {
        this.$refs.PlanDialogRef.initFun(row)
      })

      //
    }
  }
}
</script>

<style lang="scss" scoped>
.top_box {
  width: 100%;
  padding: 10px;
  background: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  ::v-deep span.el-input__suffix {
    right: 20px;
  }
  button.el-button.el-button--primary {
    height: 32px;
    padding: 0 20px;
    // line-height: 32px;
  }
  .el-form-item {
    margin-bottom: 0;
    .el-input {
      padding: 0 15px;
      width: 250px;
    }
    ::v-deep input.el-input__inner {
      height: 32px;
      line-height: 32px;
    }
  }
}

.bottom_box {
  width: 100%;
  height: calc(100vh - 178px);
  margin-top: 10px;
  border: 1px solid #e5e5e5;

  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  padding: 10px;
  .content_t {
    height: calc(100% - 70px);
    margin-top: 10px;
    // overflow: auto;
    ::v-deep td.el-table_1_column_1.el-table__cell {
      text-align: center;
    }
  }
}
</style>
